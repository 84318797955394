import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    public isLoaging = new BehaviorSubject<boolean>(false);

    public show(): void {
        this.isLoaging.next(true);
    }

    public hide(): void {
        this.isLoaging.next(false);
    }
}