import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { CEPResponse } from '@sgprev-entities';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CEPPensaoService {

    private apiUrl = environment.API_URL;

    constructor(private httpClient: HttpClient) { }

    public buscarCEP(cep: string): Observable<CEPResponse> {
        return this.httpClient.get<CEPResponse>(`${this.apiUrl}/cep/${cep}`);
    }
}
