import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DadosInstituidorDto, DadosRequerenteDto } from '@sgprev-dtos';
import { DadosPensao } from '@sgprev-entities';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class PensaoService {
    private apiUrl = `${environment.API_URL}/pensao`;

    private _dadosPensao = new BehaviorSubject<DadosPensao>(new DadosPensao());
    public shareDadosPensao$ = this._dadosPensao.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    public get dadosPensao(): DadosPensao {
        return this._dadosPensao.getValue();
    }

    public set dadosPensao(value: DadosPensao) {
        this._dadosPensao.next(value);
    }

    // TODO: fix observable type 
    public salvarRequerentes(requerentes: DadosRequerenteDto[]): Observable<any> {
        this.dadosPensao.requerentes = requerentes;
        return this.httpClient.post(`${this.apiUrl}/requerentes`, requerentes); // TODO: fix observable type );
    }

    // TODO: fix observable type
    public salvarInstituidor(dadosInstituidor: DadosInstituidorDto): Observable<any> {
        this.dadosPensao.dadosInstituidor = dadosInstituidor;

        return this.httpClient.post(`${this.apiUrl}/instituidor`, dadosInstituidor);
    }

}
