
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TipoPensao } from '@sgprev-enums';

@Injectable()
export class AppService {
    private tipoPensaoObservable = new Subject<any>();
    public tipoPensao!: TipoPensao;
    public tipoPensaoObservable$ = this.tipoPensaoObservable.asObservable();

    constructor() {
    }

    updatePensao(pensao: TipoPensao) {
        this.tipoPensao = pensao;
        this.tipoPensaoObservable.next(this.tipoPensao);
    }

}
