import { TipoAnexo } from "../enums/tipo-anexo";

export class Anexo {
    id?: number;
    url?: string;
    tipo: TipoAnexo;
    titular: string;
    
    constructor(tipo: TipoAnexo, titular: string, url?: string, id?: number) { 
        this.tipo = tipo;
        this.titular = titular;
        if (url)
            this.url = url;
        if (id)
            this.id = id;      
    }
}