import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Estado, Municipio } from '@sgprev-entities';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MunicipioPensaoService {

    private apiUrl = environment.API_URL;

    constructor(private httpClient: HttpClient) { }

    public buscarMunicipio(estadoId: number, nome: string): Observable<Municipio[]> {
        let queryParameters = new HttpParams()
            .set('estadoId', estadoId.toString())
            .set('nome', nome);
        return this.httpClient.get<Municipio[]>(`${this.apiUrl}/municipio`, { params: queryParameters });
    }
}
