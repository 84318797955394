import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Lotacao, Orgao } from '@sgprev-entities';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrgaoPensaoService {

  private apiUrl = environment.API_URL;

  constructor(private httpClient: HttpClient) { }

  public buscarOrgaos(termo?: string): Observable<Orgao[]> {
    return this.httpClient.get<Orgao[]>(`${this.apiUrl}/orgao`);
  }
}
