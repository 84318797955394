import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, catchError, map, of } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private tokenKey = 'authToken';
    private apiUrl = `${environment.API_URL}/auth` ;  

    constructor(
        private http: HttpClient
    ){}

    public set token(token: string | null) {
        if (token)
            localStorage.setItem(this.tokenKey, token)
    }

    public get token(): string | null {
        return localStorage.getItem(this.tokenKey)
    }

    public clearToken() {
        localStorage.removeItem(this.tokenKey);
    }

    public validateToken(): Observable<boolean> {
        if (!this.token) {
          return of(false);
        }
        const validateTokenUrl =  `${this.apiUrl}/token/validate`;
    
        return this.http.get<boolean>(`${validateTokenUrl}?token=${this.token}`)
          .pipe(
            map(response => !!response),
            catchError(error => {
              console.error('Token validation failed', error);
              return of(false);
            })
          );
      }
}